import Metadata from '../components/Metadata';
import Navigation from '../components/Navigation';
import React from 'react';

export default function Blog() {
  return (
    <>
      <Metadata title="Blog" />
      <div>
        Blog
        <Navigation />
      </div>
    </>
  );
}
